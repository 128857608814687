<template>
  <div>
    <DashboardNavBar/>
    <b-modal ref="submitDialog" title="Scan Upload"
      size="lg" 
      header-bg-variant="light" header-text-variant="dark"
      body-bg-variant="light" body-text-variant="dark"
      footer-bg-variant="light" footer-text-variant="dark"
      no-close-on-esc no-close-on-backdrop hide-header-close
      content-class="shadow" ok-only
      :ok-disabled="submitDialogDisabled">
        <b-alert :show="submitAlert1" variant="primary">
          <b-badge variant="primary">Queued: {{numQueued}}</b-badge>&nbsp; 
          <b-badge variant="success">Uploaded: {{numOK}}</b-badge>&nbsp;
          <b-badge v-if="numErrors > 0" variant="warning">Errors: {{numErrors}}</b-badge>
        </b-alert>
        <pre style="height: auto; max-height: 200px; overflow-x: auto;">{{submitProgressMsg}}</pre>
        <b-alert :show="uploadDoneShow" :variant="uploadDoneVariant">{{uploadDoneMsg}}</b-alert>
    </b-modal>
    <b-container fluid class="bg-custom">
      <b-row>
        <b-col>
          <span class="h1">Submit Scan (DICOM)</span>
          <b-form :validated="true">
            <b-container fluid class="px-2 py-2">
              <b-row v-if="!uploadAnon">
                <b-col>
                  <div class="alert alert-warning" role="alert"><feather type="alert-triangle"></feather>&nbsp;DICOM files will NOT be de-identified. Make sure files selected have been anonymized before submitting.</div>
                </b-col>
              </b-row>
              <b-row>
                  <b-col cols="6">
                    <b-alert show>Select folder with scan(s) to submit for processing.</b-alert>
                  </b-col>
                  <b-col cols="6">
                    <b-form-file v-model="fileList" :file-name-formatter="formatFileList" placeholder="No folder selected" directory multiple no-traverse required>
                    </b-form-file>
                  </b-col>
              </b-row>
              <b-row class="mt-1" v-if="!scanDone">
                <b-col>
                  <b-alert  show variant="info"><b-spinner></b-spinner> Scanning selected files ({{ fileI + 1 }}/{{ fileList.length }})</b-alert>
                </b-col>
              </b-row>
              <b-row :class="scanInfoClass">
                <b-col class="border border-dark rounded mt-1">
                  <b-overlay :show="allScansUploaded" rounded="sm">
                    <template #overlay>
                      <div
                        ref="dialog"
                        tabindex="-1"
                        role="dialog"
                        aria-modal="false"
                        aria-labelledby="form-confirm-label"
                        class="text-center p-3"
                      >
                        <p><strong id="form-confirm-label">All available scans have been submitted.</strong></p>
                        <div class="d-flex">
                          <b-button variant="outline-primary" class="mr-3" @click="handleWorklist">Return to Worklist</b-button>
                          <b-button variant="outline-success" @click="handleMoreScans">Submit More Scans</b-button>
                        </div>
                      </div>
                    </template>
                    <b-container fluid class="p-1 m-1">
                      <b-row>
                        <b-col cols="2">
                          <strong>SCAN INFORMATION</strong>
                        </b-col>
                        <b-col cols="10">
                          <b-alert class="p-1" show variant="secondary">Edit if needed for final report.</b-alert>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2" class="text-right">
                          <strong>Patient Name:</strong>
                        </b-col>
                        <b-col cols="4">
                          <b-form-input v-model="patient_name_f" name="patient_name_f" placeholder="<first name>" v-validate="'required|min:1'" required />
                        </b-col>
                        <b-col cols="2">
                          <b-form-input v-model="patient_name_m" name="patient_name_m" placeholder="<middle>" />
                        </b-col>
                        <b-col cols="4">
                          <b-form-input v-model="patient_name_l" name="patient_name_l" placeholder="<last name>" v-validate="'required|min:1'" required />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2" class="text-right">
                          <strong>Patient ID:</strong>
                        </b-col>
                        <b-col cols="10">
                          <b-form-input v-model="patient_id" name="patient_id" placeholder="<patient ID>" v-validate="'required|min:1'" required />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2" class="text-right">
                          <strong>Patient DOB:</strong>
                        </b-col>
                        <b-col cols="10">
                          <b-form-datepicker v-model="patient_dob" name="patient_dob" :state="patientDobValid" :show-decade-nav="true" required />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2" class="text-right">
                          <strong>Patient Sex:</strong>
                        </b-col>
                        <b-col cols="10">
                          <b-form-select v-model="patient_sex" name="patient_sex" :state="patientSexValid" required >
                            <b-form-select-option value="">Select…</b-form-select-option>
                            <b-form-select-option value="F">Female</b-form-select-option>
                            <b-form-select-option value="M">Male</b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2" class="text-right">
                          <strong>Study Date:</strong>
                        </b-col>
                        <b-col cols="10">
                          <b-form-datepicker v-model="study_date" name="study_date" :state="studyDateValid" :show-decade-nav="true" required />
                        </b-col>
                      </b-row>
                      <b-row class="mt-1" v-if="scanDone">
                        <b-col cols="8">
                          <b-alert :class="scanInfoClass" show variant="info">
                            The scan information above will be cached on this device and used to populate elements in the worklist and report.
                            <span v-if="uploadAnon">DICOM files will be de-identified before upload.</span>
                          </b-alert>
                        </b-col>
                        <b-col cols="4" :class="canSubmit ? 'text-right' : 'text-left'">
                          <b-alert v-if="!canSubmit" :class="scanInfoClass" show variant="danger">Missing required information for submit.</b-alert>
                          <b-button id="submitButton" v-show="canSubmit" :disabled="!canSubmit" variant="primary" @click="handleSubmit()"><feather type="upload-cloud"></feather>&nbsp;Submit</b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-overlay>
                </b-col>
              </b-row>
              <b-row :class="scanInfoClass">
                <b-col class="border border-dark rounded mt-1">
                  <strong>AVAILABLE SCANS</strong>
                  <b-container fluid class="p-1 m-1" ref="scanTableDiv">
                    <b-row>
                      <b-col cols="12" class="text-left">
                        <b-table id="scanTable" ref="scanTable" outlined class="small"
                              :sticky-header="tableHeight"
                              no-border-collapse
                              responsive
                              :items="scanTableItems"
                              :fields="scanTableFields"
                              primary-key="series_uid">
                          <template #cell(submitted)="data">
                            <b-icon v-if="data.item.submitted" class="p-1 m-1" title="SUBMITTED" icon="cloud-check" font-scale="2"/>
                            <b-icon v-if="!data.item.submitted && (data.item.series_uid===selectedSeriesUid)" class="p-1 m-1" title="SELECTED" icon="pencil-square" font-scale="2"/>
                            <b-button v-if="!data.item.submitted && (data.item.series_uid!==selectedSeriesUid)" title="Select" @click="handleSelectedSeries(data.item.series_uid)" size="sm">
                              <b-icon icon="cloud-plus" font-scale="2"/>
                            </b-button>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import dicomParser from 'dicom-parser'
import { zlibSync } from 'fflate'
import DashboardNavBar from './DashboardNavBar.vue'
import admdx from '../common/admdx.js'
import worklistFns from '../common/worklistFns.js'

export default {
  name: 'worklist',
  components: {
    DashboardNavBar
  },
  data() {
    return {
      elementsForWorklist: require('../common/dcm_elements_worklist.json'),
      elementsToDeidentify: {},
      elementsToDeidentifyLen: 0,
      regExpTags: [],
      tableHeight: "100px",

      // Scan Upload State Machine
      //
      deidTextMap: {},
      uidMap: {},
      uidMapLen: 0,
      scanData: {},
      fileI: 0,
      scanId: '',
      numOK: 0,
      numErrors: 0,
      scanDone: true,
      submitDialogDisabled: true,
      submitStep: 0,
      submitAlert1: false,
      uploadDoneShow: false,
      uploadDoneVariant: "success",
      uploadDoneMsg: "Upload Complete.",
      uploadProgress: false,
      submitProgressMsg: '',
      uploadProgressMsg: '',
      allScansUploaded: false,

      // Available series to select as scan to upload...
      //
      seriesMap: {},
      selectedSeriesUid: '',
      scanTableItems: [],

      // Scan Inputs
      //
      fileList: [],   
      patient_name_f: '',
      patient_name_m: '',
      patient_name_l: '',
      patient_id: '',
      patient_dob: '',
      patient_sex: '',
      series_uid: '',
      study_uid: '',
      study_date: ''
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()

    // Need to convert array of deid configurations to dictionary keyed by DICOM tag...
    //
    if (this.elementsToDeidentifyLen == 0) {
      const tmpElementsToDeidentify = require('../common/dcm_elements_anon.json')
      for (var i = 0; i < tmpElementsToDeidentify.length; i++) {
        let e = tmpElementsToDeidentify[i]
        this.elementsToDeidentify[e.tag] = e
        this.elementsToDeidentifyLen++
      }
    }
  },
  computed: {
    scanTableFields() {
      return [ 
        { "key": "submitted", "label": "", "sortable": false, "size": 0, "thClass": "bg-secondary text-white", "tdClass": 'text-center' },
        { "key": "patient_name", "label": "Patient Name", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "patient_id", "label": "Patient ID", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "description", "label": "Description", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "modality", "label": "Modality", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "study_date", "label": "Study Date", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "patient_dob", "label": "DOB", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "patient_sex", "label": "Sex", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "num_files", "label": "#", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
      ]
    },
    patientNameValid() {
      return (this.patient_name_f != '') && (this.patient_name_l != '')
    },
    patientDobValid() {
      return (this.patient_dob != '')
    },
    patientSexValid() {
      return (this.patient_sex != '')
    },
    scanInfoClass() {
      return (this.scanDone && (this.fileList.length > 0)) ? "" : "d-none"
    },
    studyDateValid() {
      return (this.study_date != '')
    },
    uploadAnon() {
      return (this.$configs.uploadAnon !== undefined) ? this.$configs.uploadAnon : true
    },
    uploadCompressed() {
      return (this.$configs.uploadCompressed !== undefined) ? this.$configs.uploadCompressed : false
    },
    canSubmit() {
      const errList = this.errors.all()
      var okay = (errList.length == 0) 
      okay &= (this.selectedSeriesUid != '') && (this.seriesMap[this.selectedSeriesUid].fileList.length > 0)
      okay &= this.patientNameValid
      okay &= this.patientDobValid
      okay &= this.patientSexValid
      okay &= this.studyDateValid
      okay &= this.scanDone
      
      return okay
    },
    numQueued() {
      var num = 0;
      if (this.selectedSeriesUid != '') {
        num = Math.max(0, this.seriesMap[this.selectedSeriesUid].fileList.length - this.numOK - this.numErrors)
      }
      return num
    }
  },
  watch: {
    fileList(/*newVal, oldVal*/) {
      this.allScansUploaded = false;
      this.selectedSeriesUid = ''
      this.handleSelectedSeries();
      this.scanDataItems = []
      this.seriesMap = {}
      this.resetScanData();
      if (this.fileList.length > 0) {
        this.scanFiles()
      }
    },
    scanDone(/*newVal, oldVal*/) {
      this.updateScanTableItems();
    },
  },
  methods: {
    handleWorklist() {
      this.$router.replace('worklist')
    },
    handleMoreScans() {
      this.fileList = []
    },
    handleResize(/*event*/) {
      try {
        let wh = (window.outerHeight > window.innerHeight) ? window.innerHeight : window.outerHeight
        let tableHeight = wh - this.$refs.scanTableDiv.getBoundingClientRect().top - 60
        this.tableHeight = (tableHeight < 100) ? "100px" : `${tableHeight}px`
      }
      catch(err) {
        this.$log.debug(`Exception: ${err.message}`)
      }
    },
    formatFileList() {
      return `${this.fileList.length} file(s) selected`
    },
    makeDeIdentifiedValue(tag, length, vr) {
      var deidText = this.deidTextMap[tag];
      if (deidText === undefined)
      {
          var now = new Date();
        if (vr === 'LO' || vr === "SH" || vr === "PN") {
          deidText = this.makeRandomString(length);
        }
        else if (vr === 'DA') {
          deidText = now.getYear() + 1900 + admdx.pad(now.getMonth() + 1, 2) + admdx.pad(now.getDate(), 2);
        }
        else if (vr === 'TM') {
          deidText = admdx.pad(now.getHours(), 2) + admdx.pad(now.getMinutes(), 2) + admdx.pad(now.getSeconds(), 2);
        }
        else if (vr === 'DT') {
          deidText = this.makeDeIdentifiedValue(tag+"DA", 0, "DA") + this.makeDeIdentifiedValue(tag+"TM", 0, "TM");
        }
        else
        {
          this.$log.warn('unknown VR:' + vr);
          deidText = '';
        }
        this.deidTextMap[tag] = deidText;
      }
      return deidText;
    },
    replaceUID(origUID)
    {
      var newUID = this.uidMap[origUID];
      if (newUID === undefined)
      {
        this.uidMapLen++;
        newUID = "1.2.3."+this.uidMapLen+"."+Math.floor(Math.random() * 1000)+this.makeDeIdentifiedValue("UID"+this.uidMapLen, 0, "DT");
        while (newUID.length < origUID.length)
        {
          newUID += '0';
        }
        if (newUID.length > origUID.length)
        {
          newUID.substring(0, origUID.length);
        }
        this.uidMap[origUID] = newUID;
      }
      return newUID;
    },
    anonDataSet(dataSet, _this=this) {
      // Need to calculate patient age for a psuedo birth date.
      //
      // OLD: getAge(this.scanData["x00100030"], this.scanData["x00080020"]);
      // NEW: calculate age based on values entered by user that will be re-inserted into
      // anonymized DICOM files by server.
      //
      const patientDOB = admdx.toDicomDate(_this.patient_dob);
      const studyDate = admdx.toDicomDate(_this.study_date);
      var age = admdx.getAge(patientDOB, studyDate);
      if (age > 90) { age = 90; }

      for (var tag in dataSet.elements)
      {
        var element = dataSet.elements[tag];
        if (element !== undefined)
        {
          var text = dataSet.string(tag);
          if (text === undefined) { text = ""; }
          var vr = element.vr;

          if (vr == "SQ")
          {
            // +TODO+ WARNING IF LITTLE ENDIAN IMPLICIT as items will be empty!
            element.items.forEach(function (item) {
                _this.anonDataSet(item.dataSet);
            });
          }
          else
          {
            var deidParams = _this.elementsToDeidentify[tag];
            if (dicomParser.isPrivateTag(tag))
            {
              deidParams = {
                "name" : "Private Element",
                "tag" : tag,
                "vr": vr,
                "vm": 1,
                "basic": "X"
              }
            }
            else if (deidParams === undefined)
            {
              // Check if one of the special tags.
              //
              for (let i = 0; i < _this.regExpTags.length; i++)
              {
                if (_this.regExpTags[i].test(tag))
                {
                  deidParams = _this.elementsToDeidentify[_this.regExpTags[i].toString().replace(/\//g, '')];
                  break;
                }
              }
            }
            if (tag == "x00080020") // Study Date (special handling to allow patient age calculation)
            {
              let deIdentifiedValue = "19900101";
              for(let i=0; i < element.length; i++) {
                let char = (deIdentifiedValue.length > i) ? deIdentifiedValue.charCodeAt(i) : 32;
                dataSet.byteArray[element.dataOffset + i] = char;
              }
            }
            else if (tag == "x00100030") // Patient DOB (special handling to allow patient age calculation)
            {
              let deIdentifiedValue = ("000"+(1990-age)).slice(-4) + "0101";
              for(let i=0; i < element.length; i++) {
                let char = (deIdentifiedValue.length > i) ? deIdentifiedValue.charCodeAt(i) : 32;
                dataSet.byteArray[element.dataOffset + i] = char;
              }
            }
            else if (deidParams !== undefined)
            {
              var basic = deidParams.basic;
              if (vr === undefined)
              {
                _this.$log.debug("tag="+tag+" vr="+vr+" deidParams.vr="+deidParams.vr);
                vr = deidParams.vr;
              }
              if (basic == "U") // replace with a new UID
              {
                let deIdentifiedValue = _this.replaceUID(text);
                for(let i=0; i < element.length; i++) {
                  let char = (deIdentifiedValue.length > i) ? deIdentifiedValue.charCodeAt(i) : '0';
                  dataSet.byteArray[element.dataOffset + i] = char;
                } 
              }
              else if (basic == "D") // replace with deidentified value
              {
                let deIdentifiedValue = _this.makeDeIdentifiedValue(tag, text.length, vr);
                for(let i=0; i < element.length; i++) {
                  let char = (deIdentifiedValue.length > i) ? deIdentifiedValue.charCodeAt(i) : 32;
                  dataSet.byteArray[element.dataOffset + i] = char;
                }
              }
              else // "X", "Z", and others - remove or zero out
              {
                for(let i=0; i < element.length; i++) {
                  dataSet.byteArray[element.dataOffset + i] = 32;
                }
              }
            }
          }
        }
      }
    },
    getScanId() {
      this.$log.debug('POST '+this.$configs.endpointsBaseUrl+'/ScanId')
      let data = {
      }
      let opts = {
        headers: {
            Authorization: 'Bearer '+this.$store.state.keycloak.token
        }
      }
      axios.post(this.$configs.endpointsBaseUrl+'/ScanId', data, opts)
      .then(response => {
        this.scanId = response.data.scanId
        this.$log.debug("this.scanId="+this.scanId)
        this.submitProgressMsg += '\nScan identifier received.'
        if (this.$configs.uploadAnon) {
          this.submitProgressMsg += '\nDe-identifying '+this.seriesMap[this.selectedSeriesUid].fileList.length+' file(s) and uploading…'
        }
        else {
          this.submitProgressMsg += '\nUploading '+this.seriesMap[this.selectedSeriesUid].fileList.length+' file(s)…'
        }
        this.uploadFiles()
      })
      .catch(err => {
        this.$log.error("Error requesting new scan ID: "+err.message)
        this.submitDialogDisabled = false
        this.submitProgressMsg += `\nUnable to get scan identifier.`
        this.uploadDoneMsg = 'Upload done with error(s).'
        this.uploadDoneVariant = "warning"
        this.uploadDoneShow = true
      })
    },
    uploadFileAnon() {
      const file = this.seriesMap[this.selectedSeriesUid].fileList[this.fileI]
      var fileName = file.name
      this.fileSize = file.size
      this.fileUploaded = 0
      var reader = new FileReader();
      var _this = this
      reader.onerror = function(/*file*/) {
        _this.$log.error("Error reading file="+fileName)
        reader.abort();
      };
      reader.onload = function(/*file*/) {
        _this.$log.debug("Read file="+fileName)
        var arrayBuffer = reader.result;
        // Here we have the file data as an ArrayBuffer. dicomParser requires as input a
        // Uint8Array so we create that here
        var byteArray = new Uint8Array(arrayBuffer);

        // Invoke the parseDicom function and get back a DataSet object with the contents
        try {
          var dataSet = dicomParser.parseDicom(byteArray);
          if(dataSet.warnings.length > 0)
          {
            dataSet.warnings.forEach(function(warning) {
              _this.$log.warn(warning);
            });
          }

          // Create de-identified values for each element.
          //
          // Ref: http://dicom.nema.org/medical/dicom/current/output/chtml/part15/chapter_E.html#sect_E.1
          //
          _this.anonDataSet(dataSet, _this);

          // Create mapping for "mangled" values (server will replace anonymized values in DICOM meta-data
          // with values from mapping).
          //
          var mangled = [
            { tag: 'x00100010', post: _this.scanData['x00100010'] },
            { tag: 'x00100020', post: _this.scanData['x00100020'] },
            { tag: 'x00100030', post: _this.scanData['x00100030'] },
            { tag: 'x00100040', post: _this.scanData['x00100040'] },
            { tag: 'x00080020', post: _this.scanData['x00080020'] }
          ];

          // Upload blob.
          //
          var uploadData = dataSet.byteArray
          var compressed = 0
          if (_this.uploadCompressed) {
            uploadData = zlibSync(uploadData, { level: 6 });
            compressed = 1
            _this.$log.debug(`Compressed data: UNCOMPRESSED size=${dataSet.byteArray.length}, COMPRESSED size=${uploadData.length}`)
          }
          var blob = new Blob([uploadData], {type: "application/octet-stream"});
          let promise = new Promise((resolve, reject) => {
            let endpoint = '/UploadDicomAnonymized/'+encodeURIComponent(_this.scanId)+"/"+encodeURIComponent(_this.fileI + 1)
            let formData = new FormData()
            formData.append('compressed', compressed)
            formData.append('object', blob, fileName)
            formData.append('mangled', JSON.stringify(mangled))
            axios.put(
              endpoint,
              formData,
              {
                baseURL: _this.$configs.endpointsBaseUrl,
                headers: {
                  Authorization: 'Bearer '+_this.$store.state.keycloak.token,
                  'Content-Type': 'multipart/form-data'
                }
              }
            )
            .then(response => {
              _this.$log.debug(response)
              resolve(response)
            })
            .catch(err => {
              _this.$log.error("UploadDicomAnonymized error: "+err.message)
              reject(err)
            })
          })

          promise.then(() => {
            _this.$log.debug("Uploaded file="+fileName)
            if (_this.seriesMap[_this.selectedSeriesUid].fileList.includes(file)) {
              _this.numOK++
            }
          })
          .catch(err => {
            _this.$log.error("Error importing object: "+err.message)
            if (_this.seriesMap[_this.selectedSeriesUid].fileList.includes(file)) {
              _this.numErrors++
              _this.uploadError(_this.fileI)
            }
          })
          .finally(() => {
            if (_this.seriesMap[_this.selectedSeriesUid].fileList.includes(file)) {
              _this.fileI++
              if (_this.fileI < _this.seriesMap[_this.selectedSeriesUid].fileList.length) {
                _this.uploadFileAnon()
              }
              else {
                // Done!
                //
                _this.uploadFilesDone()
              }
            }
            else {
              // Response is from a prior upload - ignore
              //
              _this.$log.debug("Ignoring response for previous upload request, object="+fileName)
            }
          })
        }
        catch(err)
        {
          _this.numErrors++;
          _this.$log.error(err);
          _this.uploadError(_this.fileI)
        }
      };
      reader.readAsArrayBuffer(file);
    },
    uploadFile() {
      const file = this.seriesMap[this.selectedSeriesUid].fileList[this.fileI]
      var fileName = file.name
      this.fileSize = file.size
      this.fileUploaded = 0
      var reader = new FileReader();
      var _this = this
      reader.onerror = function(/*file*/) {
        _this.$log.error("Error reading file="+fileName)
        reader.abort();
      };

      reader.onload = function(/*file*/) {
        _this.$log.debug("Read file="+fileName)
        let promise = new Promise((resolve, reject) => {
          let endpoint = '/UploadDicom/'+encodeURIComponent(_this.scanId)+"/"+encodeURIComponent(_this.fileI + 1)
          let formData = new FormData()
          var uploadData = reader.result
          var compressed = 0
          if (_this.uploadCompressed) {
            uploadData = zlibSync(new Uint8Array(uploadData), { level: 6 });
            compressed = 1
            _this.$log.debug(`Compressed data: UNCOMPRESSED size=${reader.result.byteLength}, COMPRESSED size=${uploadData.length}`)
          }
          formData.append('compressed', compressed)
          formData.append('object', new Blob([uploadData], {type: "application/octet-stream"}), fileName)
          axios.put(
            endpoint,
            formData,
            {
              baseURL: _this.$configs.endpointsBaseUrl,
              headers: {
                Authorization: 'Bearer '+_this.$store.state.keycloak.token,
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(response => {
            _this.$log.debug(response)
            resolve(response)
          })
          .catch(err => {
            _this.$log.error("UploadDicom error: "+err.message)
            reject(err)
          })
        })

        promise.then(() => {
          _this.$log.debug("Uploaded file="+fileName)
          if (_this.seriesMap[_this.selectedSeriesUid].fileList.includes(file)) {
            _this.numOK++
          }
        })
        .catch(err => {
          _this.$log.error("Error importing object: "+err.message)
          if (_this.seriesMap[_this.selectedSeriesUid].fileList.includes(file)) {
            _this.numErrors++
            _this.uploadError(_this.fileI)
          }
        })
        .finally(() => {
          if (_this.seriesMap[_this.selectedSeriesUid].fileList.includes(file)) {
            _this.fileI++
            if (_this.fileI < _this.seriesMap[_this.selectedSeriesUid].fileList.length) {
              _this.uploadFile()
            }
            else {
              // Done!
              //
              _this.uploadFilesDone()
            }
          }
          else {
            // Response is from a prior upload - ignore
            //
            _this.$log.debug("Ignoring response for previous upload request, object="+fileName)
          }
        })
      }
      reader.readAsArrayBuffer(file)
    },
    uploadFiles() {
      // Start uploading the files
      //
      this.fileI = 0

      // Replace auto-populated scanData DICOM values with UI values.
      //
      this.scanData['x00100010'] = this.patient_name_l+'^'+this.patient_name_f+'^'+this.patient_name_m+'^^^';
      this.scanData['x00100020'] = this.patient_id;
      this.scanData['x00100030'] = admdx.toDicomDate(this.patient_dob);
      this.scanData['x00100040'] = this.patient_sex;
      this.scanData['x00080020'] = admdx.toDicomDate(this.study_date);

      if (this.$configs.uploadAnon) {
        this.uploadFileAnon()
      }
      else {
        this.uploadFile()
      }
    },
    scanFile() {
      const file = this.fileList[this.fileI]
      var fileName = file.name
      var reader = new FileReader();
      var _this = this
      reader.onerror = function(/*file*/) {
        _this.$log.error("Error reading file="+fileName)
        reader.abort();
        _this.fileI++
        if (_this.fileI < _this.fileList.length) {
          _this.scanFile()
        }
        else {
          _this.scanDone = true
        }
      };
      reader.onload = function(/*file*/) {
        _this.$log.debug("Read file="+fileName)
        var arrayBuffer = reader.result;
        // Here we have the file data as an ArrayBuffer. dicomParser requires as input a
        // Uint8Array so we create that here
        var byteArray = new Uint8Array(arrayBuffer);

        // Invoke the parseDicom function and get back a DataSet object with the contents
        try {
          var dataSet = dicomParser.parseDicom(byteArray);

          var series_uid = dataSet.string("x0020000e");
          var study_uid = dataSet.string("x0020000d");
          if ((study_uid !== undefined) && (series_uid !== undefined)) {
            _this.$log.debug(`studyUID=[${study_uid}] seriesUID=[${series_uid}]`)

            if (_this.seriesMap[series_uid] === undefined) {
              var seriesData = {
                scanData: {}
              };
              for (var i = 0; i < _this.elementsForWorklist.length; i++) {
                let tag = _this.elementsForWorklist[i].tag
                seriesData.scanData[tag] = "";
                var element = dataSet.elements[tag];
                if (element !== undefined)
                {
                  var text = dataSet.string(tag);
                  if (text === undefined) { text = ""; }
                  seriesData.scanData[tag] = text;
                }
              }

              seriesData.description = dataSet.string("x0008103e")
              seriesData.modality = dataSet.string("x00080060")
              seriesData.patient_name_f = ''
              seriesData.patient_name_m = ''
              seriesData.patient_name_l = ''
              seriesData.patient_id = ''
              seriesData.patient_dob = ''
              seriesData.patient_sex = ''
              seriesData.series_uid = series_uid;
              seriesData.study_uid = study_uid;
              seriesData.study_date = '';
              seriesData.fileList = [ file ];
              seriesData.submitted = false
              var patient_name = dataSet.string("x00100010");
              if ((patient_name !== undefined) && (patient_name.length > 0)) {
                var pn_parts = patient_name.split('^');
                if (pn_parts.length > 0) {
                  seriesData.patient_name_l = pn_parts[0]
                }
                if (pn_parts.length > 1) {
                  seriesData.patient_name_f = pn_parts[1]
                }
                if (pn_parts.length > 2) {
                  seriesData.patient_name_m = pn_parts[2]
                }
              }
              var patient_id = dataSet.string("x00100020");
              if (patient_id !== undefined) { seriesData.patient_id = patient_id; }
              var patient_dob = dataSet.string("x00100030");
              if ((patient_dob !== undefined) && (patient_dob.length == 8)) {
                seriesData.patient_dob = patient_dob.substring(0, 4)+'-'+patient_dob.substring(4, 6)+'-'+patient_dob.substring(6, 8)
              }
              var patient_sex = dataSet.string("x00100040");
              if ((patient_sex !== undefined) && (patient_sex.length > 0) && ((patient_sex == 'M') || (patient_sex == 'F'))) { 
                seriesData.patient_sex = patient_sex
              }
              var study_date = dataSet.string("x00080020");
              if ((study_date !== undefined) && (study_date.length == 8)) {
                seriesData.study_date = study_date.substring(0, 4)+'-'+study_date.substring(4, 6)+'-'+study_date.substring(6, 8)
              }

              _this.seriesMap[series_uid] = seriesData;
            }
            else {
              _this.seriesMap[series_uid].fileList.push(file);
            }
          }
        }
        catch(err)
        {
          _this.$log.error(err);
        }
        finally {
          _this.fileI++
          if (_this.fileI < _this.fileList.length) {
            _this.scanFile()
          }
          else {
            _this.scanDone = true
            const seriesUids = Object.keys(_this.seriesMap);
            if (seriesUids.length == 1) {
              // Auto-populate scan inputs with scan data from only series.
              //
              _this.selectedSeriesUid = seriesUids[0];
              var selectedSeriesData = _this.seriesMap[_this.selectedSeriesUid]
              _this.scanData = selectedSeriesData.scanData;
              _this.patient_name_f = selectedSeriesData.patient_name_f
              _this.patient_name_m = selectedSeriesData.patient_name_m
              _this.patient_name_l = selectedSeriesData.patient_name_l
              _this.patient_id = selectedSeriesData.patient_id
              _this.patient_dob = selectedSeriesData.patient_dob
              _this.patient_sex = selectedSeriesData.patient_sex
              _this.series_uid = selectedSeriesData.series_uid
              _this.study_uid = selectedSeriesData.study_uid
              _this.study_date = selectedSeriesData.study_date
            }
          }
        }
      };
      reader.readAsArrayBuffer(file);
    },
    resetScanData() {
      this.scanData = {}
      this.scanData["format"] = 'DICOM';
      this.scanData["upload_anon"] = this.$configs.uploadAnon;
    },
    scanFiles() {
      // Start scanning the files
      //
      if (this.fileList.length > 0) {
        this.scanDone = false
        this.fileI = 0
        this.scanFile()
      }
    },
    updateScanTableItems() {
      this.scanTableItems = []
      const seriesUids = Object.keys(this.seriesMap);
      for (var i = 0; i < seriesUids.length; i++) {
        const seriesData = this.seriesMap[seriesUids[i]]
        var rowVariant = "light"
        if (seriesData.submitted) {
          rowVariant = "secondary"
        }
        else if (seriesData.series_uid == this.selectedSeriesUid) {
          rowVariant = "info"
        }
        const study_date = seriesData.study_date.substring(5, 7)+'/'+seriesData.study_date.substring(8, 10)+'/'+seriesData.study_date.substring(0, 4)
        const patient_dob = seriesData.patient_dob.substring(5, 7)+'/'+seriesData.patient_dob.substring(8, 10)+'/'+seriesData.patient_dob.substring(0, 4)
        this.scanTableItems.push({
          "series_uid": seriesData.series_uid,
          "patient_name": seriesData.patient_name_l+', '+seriesData.patient_name_f+' '+seriesData.patient_name_m,
          "patient_id": seriesData.patient_id,
          "description": seriesData.description,
          "modality": seriesData.modality,
          "study_date": study_date,
          "patient_dob": patient_dob,
          "patient_sex": seriesData.patient_sex,
          "num_files": seriesData.fileList.length,
          "submitted": seriesData.submitted,
          "_rowVariant": rowVariant
        })
      }
      this.handleResize()
    },
    handleInputsState() {
      this.$log.debug('handleInputsState')
    },
    handleSelectedSeries(seriesUid) {
      if (seriesUid && this.seriesMap[seriesUid] !== undefined) {
        this.selectedSeriesUid = seriesUid
        var selectedSeriesData = this.seriesMap[this.selectedSeriesUid]
        this.scanData = selectedSeriesData.scanData;
        this.patient_name_f = selectedSeriesData.patient_name_f
        this.patient_name_m = selectedSeriesData.patient_name_m
        this.patient_name_l = selectedSeriesData.patient_name_l
        this.patient_id = selectedSeriesData.patient_id
        this.patient_dob = selectedSeriesData.patient_dob
        this.patient_sex = selectedSeriesData.patient_sex
        this.series_uid = selectedSeriesData.series_uid
        this.study_uid = selectedSeriesData.study_uid
        this.study_date = selectedSeriesData.study_date
      }
      else {
        this.selectedSeriesUid = ''
        this.resetScanData();
        this.patient_name_f = ''
        this.patient_name_m = ''
        this.patient_name_l = ''
        this.patient_id = ''
        this.patient_dob = ''
        this.patient_sex = ''
        this.series_uid = ''
        this.study_uid = ''
        this.study_date = ''
      }
      this.updateScanTableItems()
    },
    handleSubmit() {
      this.$log.debug('handleSubmit')
      var rc = false;
      this.numOK = 0;
      this.numErrors = 0;
      this.submitStep = 1;
      
      this.submitDialogDisabled = true
      this.submitProgressMsg = ''
      this.uploadProgressMsg = '';
      this.submitAlert1 = true
      this.uploadDoneShow = false
      this.uploadProgress = true

      if (this.seriesMap[this.selectedSeriesUid].fileList.length > 0)
      {
        this.submitDialogDisabled = true
        this.$refs.submitDialog.show();
        this.submitProgressMsg = 'Requesting new scan identifier…';
        this.getScanId();
      }
      return rc;
    },
    uploadError(fileI) {
      this.submitProgressMsg += `\nError during upload (file ${fileI+1} of ${this.seriesMap[this.selectedSeriesUid].fileList.length}).`
    },
    uploadFilesDone() {
      let _this = this
      this.seriesMap[this.selectedSeriesUid].submitted = true;
      let nSeries = 0
      let nSubmitted = 0
      Object.keys(this.seriesMap).forEach(seriesUID => {
        nSeries++
        nSubmitted += (this.seriesMap[seriesUID].submitted) ? 1 : 0
      })
      this.allScansUploaded = (nSeries == nSubmitted)
      this.$log.debug(`nSeries=${nSeries} nSubmitted=${nSubmitted}`)
      this.updateScanTableItems();

      this.submitProgressMsg += '\nRequesting job to process uploaded scan…'
      let promise = new Promise((resolve, reject) => {
        let endpoint = '/UploadDone/'+encodeURIComponent(_this.scanId)
        let formData = new FormData()
        axios.put(
          endpoint,
          formData,
          {
            baseURL: _this.$configs.endpointsBaseUrl,
            headers: {
              Authorization: 'Bearer '+_this.$store.state.keycloak.token
            }
          }
        )
        .then(response => {
          _this.$log.debug(response)
          resolve(response)
        })
        .catch(err => {
          _this.$log.error("UploadDone axios error: "+err.message)
          reject(err)
        })
      })

      let uploadOkay = (this.numErrors == 0)
      promise.then(async () => {
        _this.$log.debug('UploadDone success')
        _this.submitProgressMsg += `\nJob queued to process uploaded scan.`

        // Add scan to worklist
        //
        let userId = _this.$store.state.keycloak.tokenParsed.preferred_username
        await worklistFns.worklist_add_scan(userId, _this.scanId, _this.scanData)
      })
      .catch(err => {
        _this.$log.error("UploadDone promise error : "+err.message)
        _this.submitProgressMsg += `\nError requesting job to process uploaded scan.`
        uploadOkay = false
      })
      .finally(() => {
        _this.selectedSeriesUid = '';
        _this.handleSelectedSeries();
        _this.submitDialogDisabled = false
        if (uploadOkay) {
          _this.uploadDoneMsg = 'Upload done.'
          _this.uploadDoneVariant = "success"
        }
        else {
          _this.uploadDoneMsg = 'Upload done with error(s).'
          _this.uploadDoneVariant = "warning"
        }
        _this.uploadDoneShow = true
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.bg-custom {
  background-color: #d0d6e2;
}
.bg-custom2 {
  background-color: #3b465e
}
/* Fix an issue in vue-bootstrap v2.22.0: https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
</style>
